import React from 'react'

import { ContextProvider } from '../../context'

import Meta from '../../components/Meta'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Lightbox from '../../components/Lightbox'
import Sharebox from '../../components/Sharebox'
import CookieBanner from '../../components/CookieBanner'

import './style.scss'

const Root = ({ children }) => (
    <ContextProvider>
        <Meta />
        <Header />
        {children}
        <Footer />
        <Lightbox />
        <Sharebox />
        <CookieBanner />
    </ContextProvider>
)

export default Root
