import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImg from 'gatsby-background-image'

import { useLightboxState } from '../../context'
import play from '../../images/play.svg'
import redDots from '../../images/dots/red.gif'
import whiteSquiggles from '../../images/squiggles/white.gif'
import yellowZigZags from '../../images/zig-zags/yellow.gif'

import SVG from '../SVG'

import './style.scss'

const GetTrailerAssets = () => {
    const { thumbnail } = useStaticQuery(graphql`
        query getTrailerAssets {
            thumbnail: file(relativePath: { eq: "trailer.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 672, maxHeight: 378) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)

    return thumbnail.childImageSharp.fluid
}

const Trailer = ({}) => {
    const thumbnail = GetTrailerAssets()
    const { setLightboxVideo, setLightboxOpen } = useLightboxState()

    const handleVideoClick = (e) => {
        e.preventDefault()

        setLightboxVideo({ providerUid: 'Ezw_eU_EyjM' })
        setLightboxOpen(true)
    }

    return (
        <div className="flex items-center w-full py-12 md:py-20 lg:py-32 relative bg-black text-white text-center overflow-hidden">
            <div className="container mx-auto px-10">
                <h2 className="max-w-md lg:max-w-lg mx-auto text-3xl md:text-4xl lg:text-5xl font-display">
                    Watch Trailer
                </h2>
                <div className="trailer__video-wrapper w-full mt-12 md:mt-20 max-w-2xl mx-auto relative">
                    <div className="rounded-lg overflow-hidden">
                        <BackgroundImg
                            fluid={[thumbnail]}
                            href="javascript:void(0);"
                            className="trailer__video-container block relative bg-white group cursor-pointer z-20"
                            onClick={handleVideoClick}
                        >
                            <div className="flex items-center justify-center w-24 h-24 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full text-center bg-red group-hover:bg-white transition">
                                <SVG
                                    src={play}
                                    className="w-6 ml-1 group-hover:text-yellow transition"
                                />
                            </div>
                        </BackgroundImg>
                    </div>
                </div>
            </div>
            <img
                src={redDots}
                className="w-12 md:w-20 h-auto absolute top-2 top-8 right-8 md:right-8 pointer-events-none"
            />
            <img
                src={whiteSquiggles}
                className="w-14 h-auto absolute bottom-12 sm:bottom-32 left-1/2 pointer-events-none"
            />
            <img
                src={yellowZigZags}
                className="hidden md:block w-32 h-auto absolute top-60 left-8 pointer-events-none"
            />
        </div>
    )
}

export default Trailer
