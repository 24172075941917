import React from 'react'
import BackgroundImg from 'gatsby-background-image'

import { useLightboxState } from '../../context'
import close from '../../images/close.svg'
import play from '../../images/play.svg'

import SVG from '../SVG'

import './style.scss'

const Lightbox = () => {
    const {
        lightboxOpen,
        setLightboxOpen,
        activeLightboxImage,
        setActiveLightboxImage,
        lightboxImages,
        setLightboxImages,
        lightboxVideo,
        setLightboxVideo,
    } = useLightboxState()

    const handleLightboxClose = () => {
        setLightboxOpen(false)

        setTimeout(() => {
            setLightboxImages([])
            setLightboxVideo(false)
        }, 250)
    }

    console.log(lightboxImages)

    return (
        <div
            className={`grid grid-cols-14 w-full h-full p-10 pb-20 xl:p-20 fixed top-0 left-0 bg-black text-center text-white transition-all ${
                lightboxOpen
                    ? 'opacity-100 visible pointer-events-auto'
                    : 'opacity-0 invisible pointer-events-none'
            } z-50`}
        >
            {lightboxImages.length > 0 && (
                <div
                    className="hidden md:flex items-center justify-center cursor-pointer hover:text-red transition"
                    onClick={() =>
                        setActiveLightboxImage(
                            Math.max(0, activeLightboxImage - 1)
                        )
                    }
                >
                    <SVG
                        src={play}
                        color="mi-light"
                        className={`transform origin-center rotate-180 ${
                            activeLightboxImage === 0
                                ? 'opacity-20 pointer-events-none'
                                : 'opacity-100'
                        }`}
                    />
                </div>
            )}
            <div className="col-span-full md:col-span-12 md:col-start-2 lightbox__inner flex items-center justify-center mx-auto w-full h-full top-0 left-0 relative">
                {lightboxImages.length > 0 && (
                    <>
                        {lightboxImages.map(({ full, alt }, i) => (
                            <>
                                <div
                                    className={`lightbox__image w-full h-full absolute top-0 left-0 transition-all duration-500 ${
                                        i === activeLightboxImage
                                            ? 'opacity-100 visible pointer-events-auto'
                                            : 'opacity-0 invisible pointer-events-none'
                                    }`}
                                    data-alt={alt}
                                >
                                    <BackgroundImg
                                        fluid={full}
                                        className="w-full h-full absolute top-0 left-0 bg-contain bg-center bg-no-repeat"
                                        style={{ position: null }}
                                    />
                                </div>
                            </>
                        ))}
                    </>
                )}
                {lightboxVideo && (
                    <div className="w-full pt-9/16 relative">
                        <iframe
                            type="text/html"
                            src={`https://www.youtube.com/embed/${lightboxVideo?.providerUid}?modestbranding=1&rel=0&autoplay=1&iframe=true&allowfullscreen=true`}
                            frameBorder="0"
                            allowfullscreen="allowfullscreen"
                            className="w-full h-full absolute top-0 left-0"
                        />
                    </div>
                )}
            </div>
            {lightboxImages.length > 0 && (
                <div
                    className="hidden md:flex items-center justify-center cursor-pointer hover:text-red transition"
                    onClick={() =>
                        setActiveLightboxImage(
                            Math.min(
                                lightboxImages.length - 1,
                                activeLightboxImage + 1
                            )
                        )
                    }
                >
                    <SVG
                        src={play}
                        color="mi-light"
                        className={
                            activeLightboxImage === lightboxImages.length - 1
                                ? 'opacity-20 pointer-events-none'
                                : 'opacity-100'
                        }
                    />
                </div>
            )}
            <SVG
                src={close}
                className="absolute top-10 right-10 cursor-pointer hover:text-red transition"
                clickHandler={() => handleLightboxClose()}
            />
        </div>
    )
}

export default Lightbox
