import React from 'react'
import { Helmet } from 'react-helmet'

import image from '../../images/share.jpg'
import icon from '../../images/icon.png'

const Meta = () => {
    const title = 'PAW Patrol The Movie Adventure City Calls'
    const description =
        'You must have brought the most Puptastic game on the shelves from Target, which means you have access to download their exclusive activity sheets, including colouring pages and design your very own PAW Patrol badge!'

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content="summary" />

            <link rel="icon" type="image/png" href={icon} sizes="16x16" />
        </Helmet>
    )
}

export default Meta
