import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const GetFooterAssets = () => {
    const { logo, marshall } = useStaticQuery(graphql`
        query getFooterAssets {
            logo: file(relativePath: { eq: "logo-w.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 301, maxHeight: 283) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            marshall: file(relativePath: { eq: "marshall.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 295, maxHeight: 365) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return {
        logo: logo.childImageSharp.fluid,
        marshall: marshall.childImageSharp.fluid,
    }
}

const Footer = ({}) => {
    const { logo, marshall } = GetFooterAssets()

    return (
        <div className="flex items-center w-full py-8 relative bg-red text-white text-center z-50">
            <div className="container mx-auto px-10">
                <div className="flex items-center">
                    <div>
                        <Img fluid={logo} className="w-28 h-auto" />
                    </div>
                    <a
                        href="https://outrightgames.com/privacy-policy/"
                        target="_blank"
                        rel="nofollow noreffer"
                        className="ml-20 text-xs"
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>
            <Img
                fluid={marshall}
                className="hidden sm:block w-48 md:w-64 h-auto bottom-8 right-8 md:right-20"
                style={{ position: 'absolute' }}
            />
        </div>
    )
}

export default Footer
