import React from 'react'
import Root from '../containers/Root'

import Hero from '../components/Hero'
import Form from '../components/Form'
import Trailer from '../components/Trailer'
import Screenshots from '../components/Screenshots'

const Home = () => (
    <Root>
        <main>
            <Hero />
            <Form />
            <Trailer />
            <Screenshots />
        </main>
    </Root>
)

export default Home
