import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImg from 'gatsby-background-image'
import Img from 'gatsby-image'

import redDots from '../../images/dots/red.gif'
import purpleLines from '../../images/straight-lines/purple.gif'

import './style.scss'

const GetHeroAssets = () => {
    const { mobile, desktop, gameLogo } = useStaticQuery(graphql`
        query getHeroAssets {
            mobile: file(relativePath: { eq: "hero.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1366, maxHeight: 853) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktop: file(relativePath: { eq: "hero.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 2049, maxHeight: 1280) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            gameLogo: file(relativePath: { eq: "game-logo.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 374, maxHeight: 394) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return {
        mobile: mobile.childImageSharp.fluid,
        desktop: desktop.childImageSharp.fluid,
        gameLogo: gameLogo.childImageSharp.fluid,
    }
}

const Hero = ({}) => {
    const { mobile, desktop, gameLogo } = GetHeroAssets()

    return (
        <div className="hero-container flex items-center justify-center relative overflow-hidden">
            <Img
                fluid={gameLogo}
                className="w-32 md:w-48 top-0 left-0 z-20"
                style={{ position: 'absolute' }}
            />
            <BackgroundImg
                fluid={[
                    mobile,
                    {
                        ...desktop,
                        media: '(min-width: 760px)',
                    },
                ]}
                className="hero w-full relative bg-gallery bg-center bg-cover bg-no-repeat text-white"
            />
            <img
                src={redDots}
                className="w-12 md:w-20 h-auto absolute bottom-2 md:bottom-8 left-2 md:left-8"
            />
            <img
                src={purpleLines}
                className="w-12 md:w-20 h-auto absolute top-1/2 right-2 md:right-8"
            />
        </div>
    )
}

export default Hero
