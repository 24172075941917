import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const GetHeaderAssets = () => {
    const { logo } = useStaticQuery(graphql`
        query getHeaderAssets {
            logo: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 128, maxHeight: 36) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)

    return {
        logo: logo.childImageSharp.fluid,
    }
}

const Header = ({}) => {
    const { logo } = GetHeaderAssets()

    return (
        <>
            <div className="flex items-center w-full h-16 px-4 fixed top-0 left-0 bg-midnight text-white z-50">
                <a href="/" className="site-header__logo">
                    <Img
                        fluid={logo}
                        className="w-32 h-auto"
                        alt="Outright Games"
                    />
                </a>
            </div>
            <div className="w-full h-16 bg-white" />
        </>
    )
}

export default Header
