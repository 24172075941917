import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { useLightboxState } from '../../context'

import play from '../../images/play.svg'
import yellowDots from '../../images/dots/yellow.gif'
import purpleZigZags from '../../images/zig-zags/purple.gif'

import SVG from '../SVG'

import './style.scss'

const GetScreenshotAssets = () => {
    const images = useStaticQuery(graphql`
        query getScreenshotAssets {
            image1Thumb: file(relativePath: { eq: "screenshots/image1.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image1Full: file(relativePath: { eq: "screenshots/image1.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image2Thumb: file(relativePath: { eq: "screenshots/image2.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image2Full: file(relativePath: { eq: "screenshots/image2.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image3Thumb: file(relativePath: { eq: "screenshots/image3.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image3Full: file(relativePath: { eq: "screenshots/image3.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image4Thumb: file(relativePath: { eq: "screenshots/image4.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image4Full: file(relativePath: { eq: "screenshots/image4.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image5Thumb: file(relativePath: { eq: "screenshots/image5.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image5Full: file(relativePath: { eq: "screenshots/image5.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image6Thumb: file(relativePath: { eq: "screenshots/image6.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image6Full: file(relativePath: { eq: "screenshots/image6.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image7Thumb: file(relativePath: { eq: "screenshots/image7.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image7Full: file(relativePath: { eq: "screenshots/image7.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image8Thumb: file(relativePath: { eq: "screenshots/image8.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image8Full: file(relativePath: { eq: "screenshots/image8.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image9Thumb: file(relativePath: { eq: "screenshots/image9.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image9Full: file(relativePath: { eq: "screenshots/image9.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image10Thumb: file(
                relativePath: { eq: "screenshots/image10.jpg" }
            ) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image10Full: file(relativePath: { eq: "screenshots/image10.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image11Thumb: file(
                relativePath: { eq: "screenshots/image11.jpg" }
            ) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 640, maxHeight: 360) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            image11Full: file(relativePath: { eq: "screenshots/image11.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)

    return [
        {
            thumb: images.image1Thumb.childImageSharp.fluid,
            full: images.image1Full.childImageSharp.fluid,
        },
        {
            thumb: images.image2Thumb.childImageSharp.fluid,
            full: images.image2Full.childImageSharp.fluid,
        },
        {
            thumb: images.image3Thumb.childImageSharp.fluid,
            full: images.image3Full.childImageSharp.fluid,
        },
        {
            thumb: images.image4Thumb.childImageSharp.fluid,
            full: images.image4Full.childImageSharp.fluid,
        },
        {
            thumb: images.image5Thumb.childImageSharp.fluid,
            full: images.image5Full.childImageSharp.fluid,
        },
        {
            thumb: images.image6Thumb.childImageSharp.fluid,
            full: images.image6Full.childImageSharp.fluid,
        },
        {
            thumb: images.image7Thumb.childImageSharp.fluid,
            full: images.image7Full.childImageSharp.fluid,
        },
        {
            thumb: images.image8Thumb.childImageSharp.fluid,
            full: images.image8Full.childImageSharp.fluid,
        },
        {
            thumb: images.image9Thumb.childImageSharp.fluid,
            full: images.image9Full.childImageSharp.fluid,
        },
        {
            thumb: images.image10Thumb.childImageSharp.fluid,
            full: images.image10Full.childImageSharp.fluid,
        },
        {
            thumb: images.image11Thumb.childImageSharp.fluid,
            full: images.image11Full.childImageSharp.fluid,
        },
    ]
}

const Card = ({ imageIndex, title, thumb, handleImageClick }) => (
    <div
        {...{
            className:
                'screenshots__slide inline-flex flex-col relative link-with-icon-hover group transition-all duration-500 select-none cursor-pointer',
            onClick: () => true,
        }}
    >
        <div className="relative" onClick={() => handleImageClick(imageIndex)}>
            <Img fluid={thumb} alt={title} className="rounded-lg" />
        </div>
    </div>
)

const Screenshots = () => {
    const { setLightboxOpen, setActiveLightboxImage, setLightboxImages } =
        useLightboxState()
    const [index, setIndex] = useState(0)
    const [direction, setDirection] = useState(1)
    const images = GetScreenshotAssets()

    const navigate = ({ forceDirection = null }) => {
        const offset =
            window.innerWidth > 768 ? (window.innerWidth > 1280 ? 3 : 3) : 2

        if (!forceDirection) {
            if (index === 1 && direction === 0) {
                setDirection(1)
            } else if (index === images.length - offset && direction === 1) {
                setDirection(0)
            }
        }

        if (
            forceDirection === 0 ||
            (forceDirection === null && direction === 0)
        )
            setIndex(Math.max(0, index - 1))
        if (
            forceDirection === 1 ||
            (forceDirection === null && direction === 1)
        )
            setIndex(Math.min(images.length - (offset - 1), index + 1))
    }

    const handleImageClick = (index) => {
        setLightboxImages(images)
        setActiveLightboxImage(index)
        setLightboxOpen(true)
    }

    const handlePrevClick = () => {
        navigate({ forceDirection: 0 })
    }

    const handleNextClick = () => {
        navigate({ forceDirection: 1 })
    }

    return (
        <div
            className={`screenshots py-12 md:py-16 relative bg-bare overflow-hidden ${
                index % 2 === 0 ? 'odd' : 'even'
            }`}
        >
            <div className="container mx-auto px-10 relative z-20">
                <h2 className="text-3xl md:text-4xl lg:text-5xl text-red font-display">
                    Screenshots
                </h2>
                <div className="grid grid-cols-14 mt-8 md:mt-12">
                    <div
                        className={`screenshots__slider grid grid-flow-col grid-cols-14 gap-4 col-span-full 2xl:col-span-10 overflow-x-visible transition duration-500 slide-${index}`}
                    >
                        {images.map((image, imageIndex) => (
                            <Card
                                {...{
                                    imageIndex,
                                    handleImageClick,
                                    ...image,
                                }}
                            />
                        ))}
                    </div>
                    <div className="flex items-center justify-start col-span-full mt-12 md:mt-20 text-center md:text-right">
                        <div
                            className="flex items-center justify-center w-20 h-20 rounded bg-lightpurple text-white hover:text-yellow hover:bg-purple cursor-pointer"
                            onClick={() => handlePrevClick()}
                        >
                            <SVG
                                src={play}
                                className="w-3 transition transform rotate-180"
                            />
                        </div>
                        <div
                            className="flex items-center justify-center w-20 h-20 ml-2 rounded bg-lightpurple text-white hover:text-yellow hover:bg-purple cursor-pointer transition"
                            onClick={() => handleNextClick()}
                        >
                            <SVG src={play} className="w-3" />
                        </div>
                    </div>
                </div>
            </div>
            <img
                src={yellowDots}
                className="w-12 md:w-20 h-auto absolute top-8 right-8 pointer-events-none"
            />
            <img
                src={purpleZigZags}
                className="hidden sm:block w-32 h-auto absolute bottom-8 md:bottom-20 right-1/3 pointer-events-none"
            />
        </div>
    )
}

export default Screenshots
