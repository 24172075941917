import React, { useState } from 'react'

import pencil from '../../images/pencil.svg'
import whiteDots from '../../images/dots/white.gif'
import redSquiggles from '../../images/squiggles/red.gif'
import yellowSquiggles from '../../images/squiggles/yellow.gif'
import yellowLines from '../../images/straight-lines/yellow.gif'
import whiteZigZags from '../../images/zig-zags/white.gif'

import SVG from '../SVG'

const validCode = 'targetpaw'

const Form = ({}) => {
    const [code, setCode] = useState('')
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()

        if (code.toLowerCase() === validCode) {
            setSuccess(true)
            setError(false)
        } else {
            setSuccess(false)
            setError(true)
        }
    }

    return (
        <div className="flex items-center w-full py-12 md:py-20 lg:py-32 relative bg-purple text-white text-center z-40">
            <div className="container mx-auto px-10 relative z-10">
                <div className="max-w-lg mx-auto">
                    <h2 className="max-w-xs md:max-w-full mx-auto text-4xl md:text-5xl lg:text-6xl text-red font-display">
                        Download your exclusive activity sheets
                    </h2>
                    <p className="mt-8 font-light">
                        You must have brought the most Puptastic game on the
                        shelves from Target, which means you have access to
                        download their exclusive activity sheets, including
                        colouring pages and design your very own PAW Patrol
                        badge! Have a PAWsome time playing PAW Patrol The Movie
                        Adventure City Calls and doing your own activity sheets!
                        We can’t wait to see them, share them online with
                        #AdventureCityCalls
                    </p>
                    {success ? (
                        <div className="mt-12 md:mt-20 p-2 md:p-4 bg-red rounded">
                            <div className="p-8 md:p-12 border-dotted border-4 border-white">
                                <h2 className="max-w-xs md:max-w-full mx-auto mb-4 text-3xl md:text-4xl text-white font-display">
                                    Congratulations!
                                </h2>
                                <p className="max-w-xs mx-auto text-xs">
                                    Download the PAW Patrol The Movie Adventure
                                    City Calls activity sheets and share them
                                    online with #AdventureCityCalls
                                </p>
                                <a
                                    href="https://outrightgames.com/wp-content/uploads/2021/07/A4_Vertical_Ad_ActivityPages.pdf"
                                    download
                                    target="_blank"
                                    rel="nofollow noreffer"
                                    className="inline-flex items-center justify-center w-full sm:w-auto h-12 mt-8 px-6 font-body text-lg bg-white hover:bg-yellow text-red hover:text-white rounded uppercase"
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                    ) : (
                        <form
                            action=""
                            className={`mt-11 md:mt-19 p-8 border-2 ${
                                error ? 'border-red' : 'border-purple'
                            } transition`}
                            onSubmit={handleSubmit}
                        >
                            <div className="flex flex-col sm:flex-row items-center justify-center">
                                <input
                                    type="text"
                                    placeholder="Enter Code"
                                    name="code"
                                    id="code"
                                    value={code}
                                    onChange={({ currentTarget: { value } }) =>
                                        setCode(value)
                                    }
                                    className="flex items-center justify-center w-full sm:w-auto h-16 px-6 font-body text-2xl text-center sm:text-left text-purple rounded-lg uppercase"
                                    required
                                />
                                <button
                                    type="submit"
                                    className="flex items-center justify-center w-full sm:w-auto h-16 mt-2 sm:mt-0 sm:ml-3 px-6 font-body text-2xl bg-red hover:bg-white text-white hover:text-red rounded-lg uppercase"
                                >
                                    Submit
                                    <SVG
                                        src={pencil}
                                        className="w-6 h-auto -mt-1 ml-4"
                                    />
                                </button>
                            </div>
                            {error ? (
                                <div className="flex flex-col sm:flex-row items-center pt-4 md:pt-8 text-red text-center sm:text-left leading-none">
                                    <h2 className="max-w-xs md:max-w-full mx-auto text-3xl md:text-4xl text-red font-display">
                                        Oops!
                                    </h2>
                                    <p className="mt-2 sm:mt-0 sm:ml-4 md:ml-6">
                                        The code you entered is invalid. Please
                                        try again.
                                    </p>
                                </div>
                            ) : null}
                        </form>
                    )}
                </div>
            </div>
            <img
                src={whiteDots}
                className="w-12 md:w-20 h-auto absolute -top-8 -right-6 md:right-8 pointer-events-none"
            />
            <img
                src={redSquiggles}
                className="hidden md:block w-14 h-auto absolute top-12 left-12 pointer-events-none"
            />
            <img
                src={yellowSquiggles}
                className="hidden md:block w-14 h-auto absolute top-2/3 left-12 pointer-events-none"
            />
            <img
                src={yellowLines}
                className="hidden md:block w-12 md:w-16 h-auto absolute top-32 left-32 pointer-events-none"
            />
            <img
                src={whiteZigZags}
                className="hidden md:block w-32 h-auto absolute bottom-8 left-20 pointer-events-none"
            />
            <img
                src={redSquiggles}
                className="hidden sm:block w-14 h-auto absolute bottom-12 right-12 pointer-events-none"
            />
        </div>
    )
}

export default Form
