import React, { createContext, useState, useContext } from 'react'

const LightboxContext = createContext()

/**
 * LightboxProvider
 *
 * @param {mixed} children
 */
const LightboxProvider = ({ children }) => {
    const [lightboxOpen, setLightboxOpen] = useState(false)
    const [activeLightboxImage, setActiveLightboxImage] = useState(0)
    const [lightboxImages, setLightboxImages] = useState([])
    const [lightboxVideo, setLightboxVideo] = useState(false)

    return (
        <LightboxContext.Provider
            value={{
                lightboxOpen,
                setLightboxOpen,
                activeLightboxImage,
                setActiveLightboxImage,
                lightboxImages,
                setLightboxImages,
                lightboxVideo,
                setLightboxVideo,
            }}
        >
            {children}
        </LightboxContext.Provider>
    )
}

/**
 * useLightboxState
 */
const useLightboxState = () => {
    const context = useContext(LightboxContext)

    if (context === undefined)
        throw new Error(
            'useLightboxState must be used within a LightboxProvider'
        )

    return context
}

export { LightboxProvider, useLightboxState }
