import React from 'react'

import facebook from '../../images/facebook.svg'
import twitter from '../../images/twitter.svg'
import instagram from '../../images/instagram.svg'
import linkedin from '../../images/linkedin.svg'
import youtube from '../../images/youtube.svg'

import SVG from '../SVG'

const Sharebox = ({}) => {
    return (
        <div className="hidden sm:flex flex-col items-center justify-center fixed top-1/2 left-0 transform -translate-y-1/2 bg-lightpurple font-bold text-red z-50">
            <a
                href="https://www.facebook.com/OutrightGames/"
                target="_blank"
                rel="nofollow noreffer"
                className="flex items-center justify-center w-12 h-12 hover:bg-purple hover:text-yellow transition text-center"
            >
                <SVG src={facebook} className="w-5 h-auto" />
            </a>
            <a
                href="https://www.twitter.com/Outright_Games/"
                target="_blank"
                rel="nofollow noreffer"
                className="flex items-center justify-center w-12 h-12 hover:bg-purple hover:text-yellow transition text-center"
            >
                <SVG src={twitter} className="w-5 h-auto" />
            </a>
            <a
                href="https://www.instagram.com/outright_games/"
                target="_blank"
                rel="nofollow noreffer"
                className="flex items-center justify-center w-12 h-12 hover:bg-purple hover:text-yellow transition text-center"
            >
                <SVG src={instagram} className="w-5 h-auto" />
            </a>
            <a
                href="https://www.linkedin.com/company/outright-games-ltd/"
                target="_blank"
                rel="nofollow noreffer"
                className="flex items-center justify-center w-12 h-12 hover:bg-purple hover:text-yellow transition text-center"
            >
                <SVG src={linkedin} className="w-5 h-auto" />
            </a>
            <a
                href="https://www.youtube.com/channel/UCiLTIbCYFkXhxldNzew_LMA/"
                target="_blank"
                rel="nofollow noreffer"
                className="flex items-center justify-center w-12 h-12 hover:bg-purple hover:text-yellow transition text-center"
            >
                <SVG src={youtube} className="w-5 h-auto" />
            </a>
        </div>
    )
}

export default Sharebox
